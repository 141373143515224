<template>
  <CommonLayout>
    <div class="cabinet">
      <div class="cabinet__inner">
        <button
          class="button-default button-back uni-back"
          @click="$router.go(-1)"
        >
          <span></span>Назад
        </button>
        <div class="cabinet__head">
          <!-- <router-link
            v-if="$route.query.team"
            class="button-default button-back cabinet__back"
            :to="{
              name: 'user',
              params: { userId: userID },
              query: { team: $route.query.team },
            }"
            ><span></span>Назад</router-link
          >
          <router-link
            v-else-if="$route.query.profile"
            class="button-default button-back cabinet__back"
            :to="{ name: 'profile' }"
            ><span></span>Назад</router-link
          >
          <router-link
            v-else
            class="button-default button-back cabinet__back"
            :to="{ name: 'user', params: { userId: userID } }"
            ><span></span>Назад</router-link
          > -->
          <h1 class="title">Благодарности</h1>
        </div>
        <div class="cabinet__body">
          <div class="gratitude">
            <div class="gratitude-form">
              <div class="gratitude-form__head">
                <div class="gratitude-form__title">
                  Отправить благодарность<Tooltip
                    :msg="`<p>Все благодарности, которые вы не отправите, сгорят ${filteredSeasonEnd}.</p><p>Одному и тому же игроку можно отправить: ${
                      maxGratitudePerCouple ? maxGratitudePerCouple + `шт.` : ''
                    } ${
                      filteredSeasonEnd ? 'до ' + filteredSeasonEnd : ''
                    }.</p>`"
                  />
                </div>
                <div class="gratitude-form__row">
                  <div class="gratitude-form__desc">Доступно для отправки</div>
                  <div class="gratitude-form__desc">
                    {{ remainingGratitude }} шт
                    <span v-if="seasonEnd"
                      >до ({{ seasonEnd | formatDate2 }})</span
                    >
                  </div>
                </div>
              </div>
              <vue-form :state="formstate" @submit.prevent="onSubmit">
                <validate class="gratitude-form__group">
                  <v-select
                    class="select-default gratitude-form__select"
                    :clearable="false"
                    :options="getUsersCoinsOverall"
                    v-model="temp.user"
                    required
                    name="user"
                    placeholder="Поиск по ФИО или нику"
                    label="search"
                  >
                    <template slot="no-options">
                      Начните печатать ФИО или ник
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                        <template v-if="option.nickname">
                          ({{ option.nickname }})
                        </template>
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                  <field-messages name="user" show="$submitted">
                    <div class="error" slot="required">
                      Поле обязательно для заполнения
                    </div>
                  </field-messages>
                </validate>
                <!-- <validate class="gratitude-form__group">
                  <v-select
                    class="select-default gratitude-form__select"
                    :clearable="false"
                    :searchable="false"
                    :options="getGratitudesValuations"
                    v-model="temp.role"
                    required
                    name="role"
                    label="text"
                    placeholder="Благодарю за ..."
                  >
                    <template slot="no-options">Пусто</template>
                  </v-select>
                  <field-messages name="role" show="$submitted">
                    <div class="error" slot="required">
                      Поле обязательно для заполнения
                    </div>
                  </field-messages>
                </validate> -->
                <validate class="gratitude-form__group">
                  <textarea
                    class="gratitude-form__textarea"
                    v-model="temp.message"
                    name="message"
                    placeholder="Введите текст"
                    maxlength="160"
                    rows="10"
                    required
                  ></textarea>
                  <field-messages name="message" show="$submitted">
                    <div class="error" slot="required">
                      Поле обязательно для заполнения
                    </div>
                  </field-messages>
                </validate>
                <div v-if="hint.show" class="gratitude-hint">
                  <!-- <button
                    @click="hint.show = false"
                    class="gratitude-hint__close"
                  ></button> -->
                  <div class="gratitude-hint__text">{{ hint.text }}</div>
                </div>
                <div class="gratitude-form__foot">
                  <button
                    class="button button-default button_big gratitude-form__button"
                    :disabled="
                      formstate.$submittedState &&
                      formstate.$submittedState.$valid
                    "
                    type="submit"
                  >
                    Отправить
                  </button>
                </div>
              </vue-form>
            </div>
            <div class="gratitude-content">
              <div class="gratitude-content__title">Лента</div>
              <div class="gratitude-content__list">
                <Loading v-if="loading" position="center" />
                <div v-else class="gratitude-list">
                  <div v-if="!list.length">
                    <div class="gratitude-list__text">Пусто</div>
                  </div>
                  <div
                    v-else
                    class="gratitude-list__item"
                    v-for="(item, index) in list"
                    :key="index"
                    :class="{ clear: !item.isReceived }"
                  >
                    <div
                      v-if="item.isReceived && item.receiverPhoto"
                      class="gratitude-list__icon"
                      :style="{
                        backgroundImage: `url(${baseUrl + item.receiverPhoto})`,
                      }"
                    ></div>
                    <div
                      v-else-if="!item.isReceived && item.senderPhoto"
                      class="gratitude-list__icon"
                      :style="{
                        backgroundImage: `url(${baseUrl + item.senderPhoto})`,
                      }"
                    ></div>
                    <div v-else class="gratitude-list__icon"></div>
                    <div class="gratitude-list__content">
                      <div class="gratitude-list__date">
                        {{ item.time | formatDate2 }}
                      </div>
                      <div class="gratitude-list__text">
                        <span>{{
                          item.isReceived ? item.receiver : item.sender
                        }}</span>
                        {{ item.isReceived ? "получил" : "отправил" }}
                        благодарность {{ item.isReceived ? "от" : "" }}
                        <span>{{
                          item.isReceived ? item.sender : item.receiver
                        }}</span>
                      </div>
                      <div class="gratitude-list__body">
                        <div
                          v-if="item.isReceived && item.senderPhoto"
                          class="gratitude-list__icon-small"
                          :style="{
                            backgroundImage: `url(${
                              baseUrl + item.senderPhoto
                            })`,
                          }"
                        ></div>
                        <div
                          v-if="!item.isReceived && item.receiverPhoto"
                          class="gratitude-list__icon-small"
                          :style="{
                            backgroundImage: `url(${
                              baseUrl + item.receiverPhoto
                            })`,
                          }"
                        ></div>
                        <div v-else class="gratitude-list__icon-small"></div>
                        <div class="gratitude-list__message">
                          {{ item.message }}
                        </div>
                      </div>
                    </div>
                    <!-- <div class="gratitude-list__from">
                      <div class="gratitude-list__user">
                        <div class="gratitude-list__user-icon default">
                          <img
                            v-if="item.senderPhoto"
                            :src="baseUrl + item.senderPhoto"
                            alt=""
                          />
                        </div>
                        {{ item.sender }}
                      </div>
                      <div class="gratitude-list__to"></div>
                      <div class="gratitude-list__user">
                        <div class="gratitude-list__user-icon default">
                          <img
                            v-if="item.receiverPhoto"
                            :src="baseUrl + item.receiverPhoto"
                            alt=""
                          />
                        </div>
                        {{ item.receiver }}
                      </div>
                    </div>
                    <div class="gratitude-list__type">
                      {{ item.valuation }}
                    </div>
                    <div class="gratitude-list__text">
                      {{ item.message }}
                    </div> -->
                  </div>
                </div>
              </div>
              <!-- <div class="cabinet-table__more">
                <button
                  v-if="limit && limit < getGratitudes.length"
                  @click="limit = getGratitudes.length"
                >
                  Смотреть все
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";
import { mapGetters, mapActions } from "vuex";
import {
  GratitudePlayer,
  GetPersonalizedGratitudes,
  GetSeasonState,
} from "@/api/Gratitudes";
import { getBaseURL, addDay } from "@/utils";
import Tooltip from "@/components/Tooltip.vue";

export default {
  props: ["userID"],
  components: {
    CommonLayout,
    Tooltip,
  },
  data() {
    return {
      remainingGratitude: 0,
      seasonEnd: null,
      maxGratitude: "",
      maxGratitudePerCouple: "",
      optionsUser: [],
      optionsRole: [
        {
          code: 1,
          label: "Помощь",
        },
      ],
      formstate: {},
      limit: 10,
      temp: {
        user: null,
        role: null,
        message: "",
      },
      hint: {
        show: false,
        text: "Одному пользователю в течение одной недели можно направить не более двух благодарностей",
      },
      list: [],
    };
  },
  computed: {
    ...mapGetters([
      // "getGratitudesStatus",
      // "getGratitudes",
      // "getGratitudesValuationsStatus",
      // "getGratitudesValuations",
      "getUsersCoinsStatus",
      "getUsersCoinsOverall",
    ]),
    loading() {
      return this.getGratitudesStatus === "loading";
    },
    baseUrl() {
      return getBaseURL();
    },
    filteredSeasonEnd() {
      return this.$options.filters.formatDate2(this.seasonEnd);
    },
  },
  created() {
    if (this.getGratitudesStatus !== "success") {
      // this.GRATITUDES_FETCH();
    }
    if (this.getGratitudesValuationsStatus !== "success") {
      // this.GRATITUDES_VALUATIONS_FETCH();
    }
    if (this.getUsersCoinsStatus !== "success") {
      this.USERS_COINS_FETCH().then(() => this.setDefaultUser());
    } else {
      this.setDefaultUser();
    }

    this.fetchData();
  },
  methods: {
    ...mapActions([
      "GRATITUDES_FETCH",
      "GRATITUDES_VALUATIONS_FETCH",
      "USERS_COINS_FETCH",
      "USER_FETCH",
      "USER_FETCH_NOTIFY",
    ]),
    fetchData() {
      const { userID } = this.$route.params;

      GetPersonalizedGratitudes(userID).then((resp) => {
        this.list = resp.data;
      });

      GetSeasonState().then((resp) => {
        const {
          remainingGratitude,
          seasonEnd,
          maxGratitude,
          maxGratitudePerCouple,
        } = resp.data;

        this.remainingGratitude = remainingGratitude;
        this.maxGratitude = maxGratitude;
        this.maxGratitudePerCouple = maxGratitudePerCouple;
        if (Date.parse(seasonEnd)) {
          this.seasonEnd = addDay(new Date(seasonEnd), -1);
        } else {
          this.seasonEnd = seasonEnd;
        }
      });
    },
    onSubmit() {
      if (this.formstate.$invalid) {
        return;
      }

      let params = {
        targetUserId: this.temp.user.id,
        message: this.temp.message,
      };

      GratitudePlayer(params)
        .then(() => {
          this.$modal.show("message", {
            message: "Благодарность успешно отправлена",
          });
          this.resetForm();

          // this.GRATITUDES_FETCH();
          this.fetchData();
          this.USER_FETCH();
          this.USER_FETCH_NOTIFY();
        })
        .catch((err) => {
          this.handleError(err);
          this.formstate._reset();
        });
    },
    resetForm() {
      this.temp = {
        user: null,
        role: null,
        message: "",
      };
      this.formstate._reset();
    },
    setDefaultUser() {
      if (this.$route.query.profile) {
        return false;
      }
      this.getUsersCoinsOverall.forEach((user) => {
        if (this.userID === user.id) {
          this.temp.user = user;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gratitude {
  position: relative;
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  &-content {
    width: 600px;
    &__title {
      font-size: 40px;
    }
    &__list {
      margin-top: 10px;
    }
  }
  &-list {
    height: 560px;
    overflow-y: auto;
    padding-right: 20px;
    @include scrollbar;
    &__item {
      display: grid;
      grid-template-columns: 60px auto;
      background: rgba(0, 255, 254, 0.2);
      border-radius: 2px;
      padding: 25px 15px;
      &.clear {
        background: none;
      }
      + .gratitude-list__item {
        margin-top: 8px;
      }
    }
    &__icon {
      width: 60px;
      height: 60px;
      border-radius: 60px;
      background-image: url(~@/assets/img/pic2/avatar-default-small.png);
      background-size: contain;
      position: relative;
      &::before {
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='10' fill='%23fff'/%3E%3Cpath d='M15.3099 10.8534c0 .0165-.0043.0289-.0043.0413 0 .0123.0043.0248.0043.0371v-.0784Zm-.3043-2.5568h-3.1771c-.3858 0-.6946-.3014-.6946-.6691V6.3389c0-.7558-.6474-1.363-1.4362-1.3382-.3644.0124-.6517.3181-.6517.6692v1.3423c0 .95-1.3934 1.6191-1.3934 2.2923v4.2872c0 .2478.1414.475.3687.5906.1286.0661.2787.1404.433.223.759.3923 1.6078.5947 2.4651.5947h2.6925c.553 0 .9989-.4295.9989-.9623v-.0868a.9083.9083 0 0 0-.1158-.4419c.2787-.1694.463-.4667.463-.8096v-.2561c0-.2726-.1158-.5121-.3045-.6898.3645-.1322.6303-.4626.6475-.855-.0087-.2272-.1029-.4337-.2487-.5947.5273-.0248.9476-.442.9476-.9583L16 9.2549c.0043-.5286-.4416-.9583-.9946-.9583h.0002Zm-8.3946.3346H4.1758c-.0987 0-.1758.0744-.1758.1652v5.3651c0 .0909.0771.1693.1758.1693H6.611c.0943 0 .1758-.0743.1758-.1693v-5.365c-.0044-.0909-.0815-.1653-.1758-.1653Zm-1.2176 4.692c-.2873 0-.5231-.2272-.5231-.504 0-.2767.2358-.5039.523-.5039.2874 0 .5232.2272.5232.5039-.0043.2768-.2358.504-.5231.504Z' fill='%235CFFED'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 0 0;
        width: 20px;
        height: 20px;
        content: "";
        top: 5px;
        right: -5px;
      }
    }
    &__content {
      padding-left: 20px;
    }
    &__date {
      font-size: 14px;
    }
    &__text {
      font-size: 18px;
      span {
        color: #52ffeb;
      }
    }
    &__body {
      margin-top: 6px;
      display: grid;
      grid-template-columns: 30px auto;
      gap: 4px;
    }
    &__message {
      font-size: 18px;
      color: #52ffeb;
      margin-top: 3px;
    }
    &__icon-small {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      background-image: url(~@/assets/img/pic2/avatar-default-small.png);
      background-size: contain;
    }
    // &__item {
    //   position: relative;
    //   padding: 15px 0;
    // }
    // &__date {
    //   font-size: 17px;
    //   color: #35978c;
    // }
    // &__from {
    //   display: flex;
    //   align-items: center;
    //   margin-top: 15px;
    // }
    // &__user {
    //   font-family: $fontRafale;
    //   font-size: 20px;
    //   width: 300px;
    //   display: grid;
    //   grid-template-columns: 48px auto;
    //   align-items: center;
    //   gap: 25px;
    //   &-icon {
    //     width: 48px;
    //     height: 48px;
    //     display: block;
    //     border-radius: 48px;
    //     overflow: hidden;
    //     margin-right: 10px;
    //     background-position: 50% 50%;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     &.default {
    //       background-image: url(~@/assets/img/pic2/avatar-default-small.png);
    //       background-size: contain;
    //     }
    //     img {
    //       width: 100%;
    //       height: 100%;
    //       object-fit: cover;
    //     }
    //   }
    // }
    // &__to {
    //   background-image: url("data:image/svg+xml,%3Csvg width='64' height='8' viewBox='0 0 64 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M63.3536 4.3536a.5001.5001 0 000-.7071l-3.182-3.182a.5.5 0 10-.7071.707L62.2929 4l-2.8284 2.8284a.5.5 0 10.7071.7071l3.182-3.182zM0 4.5h63v-1H0v1z' fill='%235CFFED'/%3E%3C/svg%3E");
    //   background-repeat: no-repeat;
    //   width: 64px;
    //   height: 8px;
    //   margin: 0 30px;
    // }
    // &__user {
    // }
    // &__type {
    //   font-size: 16px;
    //   margin-top: 20px;
    //   color: #658985;
    // }
    // &__text {
    //   font-size: 20px;
    //   margin-top: 5px;
    //   color: #52ffeb;
    // }
  }
  &-form {
    // position: absolute;
    // top: 45px;
    // right: 0;
    width: 611px;
    height: 626px;
    background-image: url(~@/assets/img/pics/1.svg);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 50px 40px;
    margin-left: -40px;
    &__head {
    }
    &__title {
      font-size: 40px;
      margin-bottom: 10px;
    }
    &__row {
      display: flex;
      justify-content: space-between;
    }
    &__desc {
      font-weight: 700;
      font-size: 18px;
      color: #50ffeb;
      margin-bottom: 30px;
    }
    &__foot {
      text-align: center;
    }
    &__group {
      margin-bottom: 20px;
      .error {
        color: red;
        font-size: 14px;
        margin-left: 20px;
        margin-bottom: -5px;
      }
    }
    &__select {
    }
    &__textarea {
      background: #024a4b;
      border: 1px solid #00ffff;
      width: 100%;
      resize: none;
      color: rgba(94, 255, 238, 1);
      font-size: 18px;
      padding: 10px 20px;
      &::placeholder {
        color: rgba(47, 189, 174, 1);
      }
    }
    &__button {
    }
  }
  &-hint {
    position: relative;
    border: 1px solid rgba(94, 255, 255, 0.5);
    margin-bottom: 25px;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 0C4.708 0 0 4.708 0 10.5 0 16.2921 4.708 21 10.5 21 16.2921 21 21 16.2921 21 10.5 21 4.708 16.2921 0 10.5 0zm0 1.1667c5.1616 0 9.3333 4.1717 9.3333 9.3333 0 5.1616-4.1717 9.3333-9.3333 9.3333-5.1616 0-9.3333-4.1717-9.3333-9.3333 0-5.1616 4.1717-9.3333 9.3333-9.3333zm-.0638 3.4909a.5834.5834 0 00-.5195.5924v8.1667a.5827.5827 0 00.1679.4178.5846.5846 0 00.6406.1286.5811.5811 0 00.1902-.1286.5832.5832 0 00.1679-.4178V5.25a.5824.5824 0 00-.1893-.4429.584.584 0 00-.4578-.1496zM10.5 14.875a.875.875 0 100 1.75.875.875 0 000-1.75z' fill='%235EFFFF'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 20px 20px;
    &__text {
      font-size: 16px;
      color: #2fbdae;
      padding: 15px 60px;
    }
    &__close {
      position: absolute;
      top: 0;
      right: 15px;
      background: url(~@/assets/img/icon/close.svg) 50% 50% no-repeat;
      width: 50px;
      height: 34px;
      border: none;
    }
  }
}
</style>
